import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";

const navigation = [
    { name: "Informacion", href: "#about" },
    { name: "Galeria", href: "#galeria" },
    //{ name: "Servicios", href: "#services" },
    //{ name: "Localizacion", href: "#location" },
    { name: "Contacto", href: "#contact" },
]

export default function Header({className}) {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className={className}>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">{process.env.REACT_APP_NAME}</span>
              <img
                className="h-8 w-auto"
                src={process.env.REACT_APP_ICON_URL}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Abrir main menu</span>
              <RxHamburgerMenu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-8">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white hover:text-white hover:bg-[#ffffff42] rounded-lg px-2 py-1">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">{process.env.REACT_APP_NAME}</span>
                <img
                  className="h-8 w-auto"
                  src={process.env.REACT_APP_ICON_URL}
                  alt=""
                />
              </a>
              <p>{process.env.REACT_APP_NAME}</p>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Cerrar menu</span>
                <IoMdClose className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 "
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

        <div className="relative isolate px-6 pt-14 lg:px-8 bg-[url(https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577050.jpg?k=d93e23070e35faa9a94cb765db96ad0ffe03c7417db76ddb28333bce971b986c&o=&hp=1)] bg-cover bg-center bg-no-repeat"> 
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">{process.env.REACT_APP_NAME}</h1>
                </div>
            </div>
        </div>

    </header>
  )
}
