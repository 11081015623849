import React from 'react'

export default function OtrosEstablecimientos() {
  return (
    <>
      <h3 className='flex items-end text-primary pr-5 text-3xl mb-5'>Otros Establecimientos</h3>
      <div className='flex flex-col md:flex-row gap-6 justify-around'>
        <figure className="relative h-96 w-full">
              <img
                  className="h-full w-full rounded-xl object-cover object-center"
                  src="https://luarcazabalahotel.com/static/IMG_5482.jpeg"
                  alt="Luarca Zabala Hotel  "
              />
              <a href='https://luarcazabalahotel.com' target='_blank'>
                <figcaption className="group hover:scale-105 cursor-pointer transition-all absolute bottom-3 left-2/4 flex w-[calc(100%-20px)] md:w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/50 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                  <div>
                    <h4 className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900">Luarca Zabala Hotel</h4>
                    <p className="antialiased font-sans text-base leading-relaxed text-gray-700 mt-2 font-normal hidden md:block">Hotel</p>
                  </div>
                  <span className="flex items-center md:hidden group-hover:block">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="md:size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z"></path>
                    </svg>
                  </span>
                </figcaption>
              </a>
          </figure>
          <figure className="relative h-96 w-full">
              <img
                  className="h-full w-full rounded-xl object-cover object-center"
                  src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/151599147.jpg?k=7ae21be779f9c338ed5176393b3848837a3346a8a1a4422bdedf8143f349a728&o=&hp=1"
                  alt="Apartamentos Rurales Playas de Luarca"
              />
              <a href='https://playasdeluarca.com/' target='_blank'>
                <figcaption className="group hover:scale-105 cursor-pointer transition-all absolute bottom-3 left-2/4 flex w-[calc(100%-20px)] md:w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/50 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                  <div>
                    <h4 className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900">Playas de Luarca</h4>
                    <p className="antialiased font-sans text-base leading-relaxed text-gray-700 mt-2 font-normal hidden md:block">Apartamentos rurales</p>
                  </div>
                  <span className="flex items-center md:hidden group-hover:block">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="md:size-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z"></path>
                    </svg>
                  </span>
                </figcaption>
              </a>
          </figure>
      </div>
    </>
  )
}
