import React, { useEffect } from "react";

import { Toaster, toast } from 'sonner'

import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

import { Galeria, Header, OtrosEstablecimientos, Localizacion, Cookies, Contacto } from "./components";

function App() {
  return (
    <>
      <Header className={"bg-white"} />
      <Toaster richColors expand={false} duration={4000} position="top-center" className="block lg:hidden" />
      <Toaster richColors expand={false} duration={4000} className="lg:block hidden"/>
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-5">
        <section className="container py-8 mx-auto lg:py-16 pb-0" id="about">
          <div className="lg:flex lg:items-center lg:-mx-4">
            <div className="lg:w-1/2 lg:px-4">
              <h2 className="text-xl font-medium text-gray-800 md:text-2xl lg:text-3xl">Luarca Experience, Apartamento Turistico</h2>
              <article className="mt-6 text-gray-500">
                <p>Luarca Experience es un apartamento turístico, moderno, luminoso, con una decoración actual y un completo equipamiento en el centro de luarca.</p>
                <br/>
                <p>Situado en la tercera planta de un edificio sin ascensor, en el centro de Luarca, a 100 metros de la estación de autobuses y a 300 de la playa y del parking público GRATUITO.</p>
              </article>
            </div>
            <div className="mt-8 lg:w-1/2 lg:px-4 lg:mt-0">
              <img className="object-cover w-full rounded-xl h-96 border shadow-lg" src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577290.jpg?k=59e420ae9104942a8144afa72527c7e5ff80a5475eca14e1ba4097b1d42b0439&o=&hp=1" alt="Video thumbnail" />
            </div>
          </div>
        </section>
        {/*
        <section class="container px-6 py-8 mx-auto lg:py-16">
          <div class="grid grid-cols-1 gap-8 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="p-8 space-y-3 border-2 border-primary rounded-xl">
              <span class="inline-block text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"></path>
                </svg>
              </span>
              <h1 class="text-2xl font-semibold text-gray-700 capitalize">web design</h1>
              <p class="text-gray-500"> Research, user experience testing, mockups, and prototypes </p>
            </div>
          </div>
        </section>
        */}
        <section className="pt-[60px]">
          <Galeria />
        </section>
        {/*
        <section className="pt-[60px]">
          <Servicios />
        </section>
        <section className="pt-[60px]">
          <Localizacion />
        </section>
        */}
        <section className="pt-[60px]">
          <Contacto />
        </section>
        <section className="pt-[60px]">
          <OtrosEstablecimientos />
        </section>
      </main>
      <Cookies />
      <Analytics />
      <SpeedInsights />
    </>
  );
}

export default App;
