import React, { useEffect, useState } from 'react'

const fotos = [
    [
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577050.jpg?k=d93e23070e35faa9a94cb765db96ad0ffe03c7417db76ddb28333bce971b986c&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577263.jpg?k=3aa1826982ffc38fb27a7c11699199a30ce8643edf620fac336e9b057eb08244&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577341.jpg?k=54fe3fd2fb4abd42b8929bdf46c1becd1f8f6c00af48ff2730561e51f230630b&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577214.jpg?k=3f6e44c157dc4e948a4dc6a4a34f606fde227e40caab0b2d07a68f728d75a3bb&o=&hp=1",
            "alt": ""
        }
    ],
    [
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577290.jpg?k=59e420ae9104942a8144afa72527c7e5ff80a5475eca14e1ba4097b1d42b0439&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577372.jpg?k=9bc4c1bd039be063c6958756a0a9d8e5062703e1dfdccf8e83092249fb45f18b&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577397.jpg?k=ea19e291abf227a32dd50ed577d3f1915a152badb74a73d18a4a194ac06a1704&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577237.jpg?k=4c98a9daa93d19ea0dee347c15c840452862e826d983a9ba852482ec59fa5751&o=&hp=1",
            "alt": ""
        }
    ],
    [
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577205.jpg?k=dde1aba03e106c5d7c03cd60c9b5e7b53beceee363133499b3f5f575a5636fe9&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577225.jpg?k=01aea93842827287b87d6daeccf99bbc9cef00cb554de1cd58095a53921cc288&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577309.jpg?k=67d357e781da3b1eb587d1aeb374925999d863869f79231c363392fe269c1c72&o=&hp=1",
            "alt": ""
        },
        {
            "src": "https://cf.bstatic.com/xdata/images/hotel/max1024x768/470577329.jpg?k=4775ec2e97f86c57c387c94cda77404bc5614f9cfc7ad7bb2a08894e4e4e249a&o=&hp=1",
            "alt": ""
        }
    ]
]

export default function Galeria() {



    return (
        <>
            <h3 className='flex items-end text-primary pr-5 text-3xl mb-5 pt-3' id="galeria">Galeria</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {
                    fotos.map((foto, index) => {

                        return (
                            <div className="grid gap-4" key={index}>
                                {
                                    foto.map((f, i) => {
                                        return (
                                            <div key={i}>
                                                <img className="h-auto max-w-full rounded-lg" src={f.src} alt="" />
                                            </div>
                                        )
                                    }) 
                                }
                            </div>
                        )

                    })
                }
            </div>
        </>
    )
}
