import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { BrowserRouter as Router } from 'react-router-dom'

import esTranslation from './idiomas/es.json'; // Importa el archivo de traducción para español

// Configura el paquete de internacionalización
i18n
  .use(initReactI18next) // Usa react-i18next
  .init({
    resources: {
      es: {
        translation: esTranslation // Asigna las traducciones en español
      }
    },
    lng: 'es', // Establece el idioma por defecto
    fallbackLng: 'en', // Establece el idioma de respaldo
    interpolation: {
      escapeValue: false // No escape los valores
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      <App />
  </Router>
);